import React from 'react';
import CityGuide from '../../../components/guides/CityComponent';
import Footer from '../../../components/FooterComponent';

import { Miami } from '../../../guides/countries/usa/miami';

function MiamiPage(props) {
    return (
        <div>
            <CityGuide city={ Miami } pathname={ props.location.pathname } urlBack="/travel-guides/north-america" />
            <Footer />
        </div>
    );
}

export default MiamiPage;
