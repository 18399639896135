import React from 'react';

export const Miami = {
    "name": "Miami",
    "link": "miami",
    "country": "United States",
    "continent": "North America",
    "thumb": "https://live.staticflickr.com/65535/50380505717_ea55236c32_t.jpg",
    "image": "https://live.staticflickr.com/65535/50380505717_083d7afa28_o.jpg",
    "caption": "Lights Out for Beach and Party",

    "description": "Forget everything you have ever heard about this city. We are no more in the Cocaine Cowboys-era, see-and-be-seen crowds packed racing to raucous South Beach parties in overpriced sports car. Of course, you will still get a smell of all the things, mostly in South Beach, but now it's Wynwood's vibrant arts district, Brickell's myriad eateries and Downtown Miami's new cocktail scene, that is earning this city a new reputation as one of the country's most prominent cultural meeting spots.",

        "nrDays":<p>
                Miami is a big city and if you want to have a little taste of the city's culture and at the same time visit some of the
                famous surroundings spots, like <b>Everglades</b> or <b>Florida Keys</b> you should stay here for at least <b>4 days</b>.
                If you want to stay more time on South Beach to relax and enjoy your stay, take 1 or 2 extra days for that.
            </p>,

    "whenVisit": <p>
            May/June is the high season, when the city is crowded with tourists. Personally, we recommend to avoid the months
            of July and August and try to visit the city between <b>March and May</b>. the sun shines with temperatures around 20ºC/30ºC during the day.
            Aside from the good weather, a spring visit allows you to sidestep the peak winter rates.
            <br/><br/>
            From June to November it is the hurricane season in Florida, we recommend you avoid those months.
            </p>,

    "mobility": <p>
        As with almost all USA cities, the spots you will want to visit are spread on different districts from the city, and thus far away from each others.
        The two points where you can barely skip from having a car (or use an Uber) are in the city centre, around Downtown area and in <b>South Beach</b>.
        <br/><br/>
        Everything else is dislocated, and if you're comfortable driving around, we recommend you <b>rent a car</b>. Personally, we have good experiences with <b>Budget</b> and <b>Avis</b> when on the United States.
        <br/><br/>
        Uber or Lyft are two other options to move around the city. Not so much economic, but a comfortable solution if you want to stay only around the city.
    </p>,

    "safety": <p>
    Considering that Miami is part of USA, it is relatively safe when compared to other cities in the country.
    However, you still need to be careful with <b>pickpocketing</b> and with some <b>dangerous areas that should be avoided</b>, like <b>Overtown</b> and <b>Liberty City</b>.
    <br/><br/>
    Don't wander by Wynwood's borders at night, especially alone. Ah, and also important, don't tell to a local that Miami Beach is Miami, they
    really don't like it.
    Contrary to popular belief (and the general rule of thumb) Miami Beach is a very safe place and you don’t have to stay away from it. Still, while on the beach,
    keep an eye out for your stuff as they may disappear in the blink of an eye.
    </p>,

    "itinerary": {
        "description": <p>
            Miami has some interesting places to visit but most of them are quite far away from each other.
            We will suggest a 4-day itinerary in which two of the days are used for day trips.
            If you want to stay a little more time in the South Beach Area to relax there, take 1 or 2 extra days for that.
            <br/><br/>
            Whether or not you are a fan of sports, you should try to attend a sport match.
            Depending on the season, join the crowds and cheer for the <b>Miami Heat</b> (National Basketball Association), the <b>Miami Marlins</b> (Major League Baseball) or the <b>Miami Dolphins</b> (National Football League).
            This will not be part of the itinerary, and you will need to search the day when there are games.
        </p>,
        "days": [
            {
                "description": <p>
                    Staying directly in Miami Beach is the right move for your first day (we recommend you stay in this area), because you’ll want to
                    avoid traffic and other delays that might eat up your R&R time. Grab a quick lunch from the outdoor counters at La Sandwicherie or My Ceviche
                    in South Beach. While you’re in the neighborhood, go look for the <b>Art Deco</b>, <b>Miami Modern (MiMo)</b>, and the <b>Mediterranean Revival</b> gems
                    on a walking tour.
                    <br/><br/>
                    Get a nice place for dinner, a good hamburger or fried chicken (yes, here you will eat a lot of fast food) should satisfy you,
                    and enjoy the nightlife in the first avenue closer to the beach, or instead of that hop in a good night club.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50498676152_fe676f8c59_o.jpg",
                "caption": "South Beach in the morning."
            },
            {
                "description": <p>
                    For the second day, we recommend a visit to the <b>Everglades National Park</b>. You can get a day tour organized by some
                    agency, or you can arrange something on your own. Having a car gives you a lot of easy possibilities to go whenever you want.
                    The wildlife in the park is incredible.
                    <br/><br/>
                    You can take a boat tour or kayaking around the swamp to view the crocodiles and dolphins, go hiking its many trails with the chance
                    of see turtles and alligators along the way.
                    At the end of the day, while you return to your hotel, make a quick stop at the Keys Outlet if you want to buy some new clothes at nice prices.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50498676072_7a09336b42_o.jpg",
                "caption": "Alligator in Everglades National Park."
            },
            {
                "description": <p>
                    For the third we suggest a long car journey along the <b>famous Keys</b>. Go on a trip between all the bridges and swamps,
                    until you get to the farthest point, <b>Key West</b>. It is a scenic drive, and you should make some stops along the way to capture some great pictures.
                </p>,
                "image": "https://live.staticflickr.com/65535/50497813058_0f97f44c51_o.jpg",
                "caption": "Overseas Hwy, Key Largo."
            },
            {
                "description": <p>
                    Don’t eat a big breakfast this morning. Instead, start your second day in Miami with an incredible <b>Little Havana food tour</b>.
                    We recommend the <i>empanadas</i> from <b>El Pub</b>, <i>mojitos</i> from the <b>Ball & Chain</b> and <i>ice cream</i> from <b>Azucar</b>.
                    <br/><br/>
                    After visiting this small part of the city make your way to the <b>Wynwood district</b>. Here you should visit the <b>Wynwood Walls</b>, a collection
                    of incredibly impressive street art housed in a park. Get a late lunch at a good hamburgery here. At the afternoon head to <b>Downtown</b> and visit the <b>Bayside Marketplace</b>, with the <b>Bayfront Park</b> and the surrounding environment. Stay here for dinner.
                </p>,
                "image": "https://live.staticflickr.com/65535/50498526196_32386acb3b_o.jpg",
                "caption": "Wynwood Walls, Miami."
            }
        ]
    },

    "highlights": [
        {
            "name": "Miami Downtown",
            "description": <p>
            Since it is one of the most famous areas of the city, you should make a stop here. Get around the streets and embrace the huge buildings.
            With a modern-shopping-meets-harbor vibe and an extremely popular place for locals, don't forget to visit the <b>Bayside Marketplace</b> and the <b>Bayfront Park</b>.
            If you have the time, take a Miami boat tour, you will get nice pictures of the skyline.
            </p>,
            "image": "https://live.staticflickr.com/65535/50497812973_fc57d65bb5_o.jpg"
        },
        {
            "name": "Little Havana",
            "description": <p>
                Despite its relatively small size and mostly residential area, Little Havana and its main thoroughfare, <b>Calle Ocho</b>,
                is one of the most famous neighborhoods in Miami, with a compelling history, distinct vibe, and magnificent food culture.
                Trying the food in this area of the city is a must.
            </p>,
            "image": "https://live.staticflickr.com/65535/50497812953_ac70a66a5e_o.jpg"
        },
        {
            "name": "Wynwood District",
            "description": <p>
                Once an unloved industrial district, now Wynwood has been transformed into one of Miami’s trendiest neighborhoods,
                bursting with everything from amazing street art to eclectic boutiques, and visiting it is now considered a must in the city's guides.
                The famous spot here is the <b>Wynwood Walls</b>, a group of amazing street art housed in a park.
                The entire neighborhood is covered in colorful art, and there are more galleries, boutiques, and trendy restaurants around there.
            </p>,
            "image": "https://live.staticflickr.com/65535/50497812893_0a9934eaeb_o.jpg"
        },
        {
            "name": "Art Deco",
            "description": <p>
                Very famous by its Art Deco (and Mediterranean Revival, and Miami Modern) buildings, Miami has a "collection" of streets that can make
                you feel like you are standing aside a delightful retro throwback. Get around the <b>Ocean Drive</b> and <b>Collins Ave</b> to get in contact
                with some of this architecture. If you want to learn more about the history of this part of the city, take a walking tour here.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498675927_624b233302_o.jpg"
        },
        {
            "name": "South Beach",
            "description": <p>
                Famous for the best beaches in Miami. Feel free to walk along its shore for as much as you like before finding a place to set up and relax for the day.
                Don’t forget to stop and take a photo with a few of the amazing Art Deco lifeguard stands that the beach is known for!
            </p>,
            "image": "https://live.staticflickr.com/65535/50498675902_9ecae697ef_o.jpg"
        },
        {
            "name": "Everglades National Park",
            "description": <p>
                One of Florida’s most gorgeous natural assets, the Everglades is a must place that you’ll want to visit during
                your stay in Miami. Everglades National Park spans 1.5 million acres across southern Florida and there are two ways
                to access the park from Miami-Dade County. Don't skip the boat tours and the trails here, so you will need almost a day to
                have access to most parts of the park.
            </p>,
            "image": "https://live.staticflickr.com/65535/50497812828_3ed08bdf94_o.jpg"
        },
        {
            "name": "The Keys",
            "description": <p>
                The Florida Keys are a super thin stretch of land from <b>Key Largo</b> to <b>Key West</b>. There’s basically one main highway that
                runs both ways through it, so it’s very hard to get lost! On your way to Key West make at least two stops in <b>Islamorada</b> and <b>Marathon</b>.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498526036_27dd88ee0b_o.jpg"
        }
    ],

    "food": {
        "description": <p>
            Miami has a strong Spanish influence in the city, so you will be able to taste two different cuisines here.
            The typical Cuban taste, like ropa vieja, and the strong American flavor, like its burgers and fried chicken.
            </p>,

        "restaurants": [
            {
                "name": "Havana 1957 Cuban Cuisine Espanola Way",
                "description": "Try the famous Ropa Vieja here to taste one of the Cuba famous traditional dishes. The service is good and fast.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50498675842_50ed95063f_o.jpg"
            },
            {
                "name": "11th Street Diner",
                "description": "The portions are huge, the prices are reasonable and the staff are super friendly. A typical American restaurant.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50498675802_22479efa83_o.jpg"
            },
            {
                "name": "El Pub",
                "description": "Come here for the coffee, for the atmosphere, and especially for the empanadas",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50498525971_012bd37df8_o.jpg"
            },
            {
                "name": "The Salty Donut",
                "description": "Artisanal donuts are incredibly popular across the USA right now, and you will love them at this place.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50497812673_964936aebf_o.jpg"
            },
            {
                "name": "Alma Mexicana",
                "description": "This place simply has great food and you will not regret if you come here. We highly recommend this to anyone who likes Mexican food.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50498525911_bfa04f803a_o.jpg"
            },
            {
                "name": "Azucar Ice Cream Factory",
                "description": "While walking around Little Havana, stop here for a cool off. Everyone enjoys their ice cream.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50497812613_8ac54e6462_o.jpg"
            },
            {
                "name": "Santorini by Georgios",
                "description": "The atmosphere is beautiful especially at night with the outdoor seating with cabanas. Famous by its seafood.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50498525856_8166a42ba0_o.jpg"
            },
            {
                "name": "On Ocean 7 Cafe",
                "description": "The food, drinks, and music was amazing. Another typical American restaurant.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50497812578_42c76cd7b1_o.jpg"
            }
        ]
    },

    "stay": {
        "description": <p>
               Miami has a large choice of accommodations, from one star to five star hotels. As a big touristic city you will not have
               any difficulties finding out the place that is more comfortable for your stay.
               For the best experience, we recommend you stay around Miami Beach or South Beach, close to the beach.
            <br/><br/>
            Below we give you some suggestions of places to stay for every budget (Take into account that Miami is an expensive city,
            so you will have difficulty finding a really cheap place at a good spot).
        </p>,

        "budget": [
            {
                "name": "Ocean Reef Suites",
                "description": "This apart-hotel on Miami Beach is in the Art Deco District, 2 minutes’ walk from the beach. A very comfortable solution, highly recommended by us.",
                "image": "https://live.staticflickr.com/65535/50497812533_f818a060a1_o.jpg",
                "link": "/"
            },
            {
                "name": "SoBe Hostel & Bar",
                "description": "This South Beach hostel is a 5 minutes’ walk from the beach. Features include a bar.",
                "image": "https://live.staticflickr.com/65535/50498675567_1522241da7_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "Hotel Impala",
                "description": "In the vibrant Art Deco area, this spot with a perfect location is a good place for your stay.",
                "image": "https://live.staticflickr.com/65535/50498675527_af79e74eaf_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "The Marlin Hotel",
                "description": "Close to the beach, with large rooms and a fully-stocked minibar, this is a comfortable option for your vacations.",
                "image": "https://live.staticflickr.com/65535/50498525731_5896566f43_o.jpg",
                "link": "/"
            }
        ]
    }
}